/* Estilo para a lista de dependentes */
.dependants-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.dependants-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f9fa;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 8px;
  font-size: 16px;
}

.dependants-list li strong {
  color: #333;
}

.dependants-list li button {
  background-color: #ff4d4d;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
}

.dependants-list li button:hover {
  background-color: #e60000;
}

/* Estilo para a seção de adicionar dependente */
.add-dependant {
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
  margin-top: 20px;
}

.add-dependant h2 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #555;
}

.add-dependant input {
  width: calc(100% - 20px);
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
}

.cad-dep-interno {
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  height: auto;

}

.cad-dep-interno-form-container{
  margin-top: 2vh;
  margin-bottom: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  height: auto;
}

.add-dependant button {
  width: 100%;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s ease;
}

.add-dependant button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.add-dependant button:hover:enabled {
  background-color: #0056b3;
}

/* Estilo para erros */
.add-dependant p {
  color: red;
  font-size: 14px;
}

/* Modal de confirmação */
.modal-remove-dependant {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-remove-dependant div {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 90%;
  max-width: 400px;
}

.modal-remove-dependant p {
  margin-bottom: 20px;
  font-size: 16px;
}

.modal-remove-dependant button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.2s ease;
}

.modal-remove-dependant button:first-of-type {
  background-color: #ff4d4d;
  color: white;
}

.modal-remove-dependant button:first-of-type:hover {
  background-color: #e60000;
}

.modal-remove-dependant button:last-of-type {
  background-color: #ccc;
  color: #333;
}

.modal-remove-dependant button:last-of-type:hover {
  background-color: #b3b3b3;
}

.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #0056b3;
}