@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (min-width: 601px) {


  * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
    }


    .people-card-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
     
    }
/* 
    .people-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border: 1px solid blue;
      margin-top: 1rem;
      margin-bottom: 1rem;
    } */

    .people-card {
      
      width: 100%;
      min-height: 9vh;
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: row;
      background-color: white;
      position: relative; /* Adiciona uma posição relativa para a div */
    
    }
    
    .people-card ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15%; 
      width: 75%; 
      height: 0.13rem;
      background-color: #87CEEB;
    }

    /* .position-1 {
      color: red;
    }

    .position-2 {
      color: yellow;
    }

    .position-3 {
      color: green;
    } */

  

    .perfil-icon {
      width: 5vh;
      height: 5vh;
    }

    .people-place-container {
      font-family: 'Montserrat', sans-serif;;
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
     
      width: 30vw;
    }

    .person-quee-name {
      font-size:2vh;
      font-weight: 600;
      color:rgba(36, 36, 36, 0.921);
      font-style: italic;
    }

    .number-quee {
      font-size:3vh;
      font-style: italic;
      margin-left: 5vw;
      color: #6dc6ff; /* Azul claro */
      text-shadow: 0 0 3px #add8e6, 0 0 6px #add8e6, 0 0 9px #add8e6, 0 0 12px #6dc6ff, 0 0 18px #6dc6ff, 0 0 24px #6dc6ff, 0 0 33px #6dc6ff, 0 0 45px #6dc6ff;
    }

    .name-red {
      color:rgba(254, 21, 21, 0.795);
      font-size:2vh;
      font-weight: 600;
      font-style: italic;
    }

  }