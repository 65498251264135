.treatment-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
}

/* Botão de expandir/retrair */
.toggle-btn {
  padding: 9px 19px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #5468ff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1.5vh;
  margin-bottom: 1.5vh;
  width: 75vw;
}

.toggle-btn:hover {
  background-color: #3c4ab7;
}

/* Filtro */
.filter-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 20px 0;
  gap: 10px;
}

.filter-container label {
  display: flex;
  flex-direction: column;
  font-family: 'Arial', sans-serif;
  font-size: 0.9rem;
  color: #555;
}

.filter-container input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

/* Lista */
.treatment-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

/* Card */
.treatment-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 300px;
  max-width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.treatment-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.treatment-card strong {
  color: #5468ff;
  font-weight: bold;
}
