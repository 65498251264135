@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.box-explanation-main-container{
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    min-height: 25vh;
   
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.box-exp-subtitle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  width: 90%;
  color: rgb(82, 82, 82);
  font-family: 'Montserrat', sans-serif;
 
}

.box-exp-first-paragraf{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  min-height:25vh ;
  color: #b8743d;
  

}


.box-exp-first-paragraf-repair{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  min-height:20vh ;
  /* color: #b8743d; */
  color: #26b689;
  

}

.box-exp-first-paragraf-smart{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  min-height:27vh;
  color: #0073fff5;

}

.box-exp-first-paragraf-vip{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  min-height:40vh ;
  color:  #b68a06ef;

}

.box-exp-first-paragraf-special{
  display: flex;
  justify-content: space-evenly;
  align-items: center;  
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  text-align: left;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  min-height:40vh ;
  color:  #819aa2;

}

.box-exp-advantages-title{
  font-style: italic;
  align-self: flex-start;
  padding-top: 12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  color: rgb(82, 82, 82);
}

.box-exp-advantages{
  padding-top: 1px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  color: #b8743d;
  text-align: left;
}

.box-exp-advantages-repair{
  padding-top: 1px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  /* color: #b8743d; */
  color: #26b689;
  text-align: left;
}

.box-exp-advantages-smart{
  padding-top: 1px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  color: #0073ffe1;
  text-align: left;
}

.box-exp-advantages-vip{
  padding-top: 1px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  color: #b68a06ef;
  text-align: left;
}

.box-exp-advantages-special{
  padding-top: 1px;
  padding-bottom: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 90%;
  color:#819aa2;
  text-align: left;
}

.box-exp-button {
 
    align-items: center;
    background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%);
    color: #fff;
    font-family: "JetBrains Mono",monospace;
    height: 40px;
    width: 100%;
    font-size: 1.2rem; 
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  
}

.box-exp-button-repair {
 
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%);
  color: #2e2e2e;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 100%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0);


}

.box-exp-button-smart {
 
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 100%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

}

.box-exp-button-vip {
 
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #ffd700 0%, #b29500 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 100%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

}

.box-exp-button-special {
 
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #e0e9f8 0%, #a3b9d9 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 100%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

}



.advantages-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  
}

.advantages-sub-container{
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 
}

.box-exp-button-stagio2-voltar{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  
}

.box-exp-button-stagio2-voltar-repair{
  align-items: center;
  /* background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%); */
  /* color: #fff; */
  color: #2e2e2e;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0);
}

.box-exp-button-stagio2-valores{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
}

.box-exp-button-stagio2-valores-repair{
  align-items: center;
  /* background-image: radial-gradient(100% 100% at 100% 0, #cd7f32 0%, #7c4d24 100%); */
  /* color: #fff; */
  color: #2e2e2e;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0);
}


.box-exp-button-smart-stagio2-voltar{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
}

.box-exp-button-smart-stagio2-valores{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
}

.box-exp-button-vip-stagio2-voltar{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #ffd700 0%, #b29500 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
}

.box-exp-button-vip-stagio2-valores{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #ffd700 0%, #b29500 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
}

.box-exp-button-special-stagio2-voltar{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #e0e9f8 0%, #a3b9d9 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
}

.box-exp-button-special-stagio2-valores{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #e0e9f8 0%, #a3b9d9 100%);
  color: #fff;
  font-family: "JetBrains Mono",monospace;
  height: 40px;
  width: 45%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
}

.box-exp-btn-stagio2-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
}