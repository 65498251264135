@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
  }

  .auth-pat-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
  }