@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;

  }
  
  .login-form-container-ort{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    min-height: 50vh;
    width: 100vw;
  }

  .text-box-container-ort{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    height: 12vh;
   
   
  }

  .login-form-container-sec-ort{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column; 
    height: 39vh;
    width:90vw ;
   
  }


  .login-form-label-ort{
    font-size: 1.4rem;
    font-style: italic;
    color: rgba(46, 46, 46, 0.941)
  }

  .field-form-ort{
 
    background-color: rgba(0, 157, 255, 0.158);
    width: 90%;
    height: 9vh;
    border-bottom:1px solid rgba(46, 46, 46, 0.941);
    font-size: 1.4rem;
    
  }


  .field-form-ort::placeholder{
    font-size: 1.33rem;
    letter-spacing: 0.05rem;
    text-align: center;
    font-style: italic;
    color: rgba(46, 46, 46, 0.697)
    
  }

  .field-form-ort-emailCPF{
 
    background-color: rgba(0, 157, 255, 0.158);
    width: 90%;
    height: 9vh;
    border-bottom:1px solid rgba(46, 46, 46, 0.941);
    font-size: 1.4rem;
    
  }

  .field-form-ort-emailCPF::placeholder{
    font-size: 1.15rem;
    letter-spacing: 0.05rem;
    text-align: center;
    font-style: italic;
    color: rgba(46, 46, 46, 0.697)
    
  }

  .error-message-ort{
    color: rgba(255, 0, 0, 0.863);
    font-size: 0.95rem;
  }

  