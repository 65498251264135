.product-details{
  width: 90%;
  margin-bottom: 20px;  
}

.product-itens-list{
  display: flex;
  justify-content: center;
  align-items: left;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 10px;
  text-align: left;
  font-size: 1.3rem;
  color: white;
  font-family: sans-serif;
  font-weight: 600;
  border:2px solid white ;
  border-left: none;
  border-right: none;

  li{
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
}

.button-info-values{
  align-items: center;
  background-image: radial-gradient(100% 100% at 100% 0, #a3e635 0%, #16a34a 100%);
  color: #fff;
    font-family: "JetBrains Mono",monospace;
  height: 4vh;
  width: 80%;
  font-size: 1.2rem; 
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 5px;
  margin-top: 10px;
}

.uni-credit-value{
  background: linear-gradient(135deg, #4d4d4d8b, #1c1c1c); 
  width: 100%;
font-size: 1.1rem;
}

.uni-credit-value-sp{
  background: linear-gradient(135deg, #4d4d4d8b, #1c1c1c); 
  width: 100%;
font-size: 1.1rem;
color: whitesmoke;
}