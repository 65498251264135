/* Container principal do carrossel */
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  margin-top: 3vh;
 
}

.carousel-sub-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #1a73e8; /* Azul moderno */
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.7vh;
  border-bottom: 1px solid gray;
  width: 72vw;
  margin-top: 2vh;
}

.unlock-infos {
  color: #1a73e8; /* Azul moderno */
  font-size: 1rem;
  margin-bottom:2vh;
  margin-top: 1vh;
}

/* Container do carrossel */
.carousel {
  position: relative;
  overflow: hidden;
  width: 80%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
}

/* Itens do carrossel */
.carousel-item {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  transition: all 0.3s ease-in-out;
  min-height: 46vh;
  
}

.content-text-aling {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.carousel-item.active {
  display: flex;
  transform: scale(1);
  opacity: 1;
  
}

.carousel-item.inactive {
  opacity: 0;
  transform: scale(0.9);
}

/* Títulos das seções */
.carousel-item h4 {
  font-size: 1.5rem;
  font-weight: bold;
  /* color: #1a73e8; */
  color: whitesmoke;
}

/* Texto e spans */
.carousel-item p {
  font-size: 1rem;
  color: #444;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 35vw;
  width: 40vw;
  border-bottom: 1px solid gray;
  font-weight: 600;
  
}

.carousel-item p span {
  font-weight: bold;
  color: #1a73e8;
}


.carousel-item li {
  font-size: 1rem;
  color: #444;
  margin: 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 55vw;
  border-bottom: 1px solid gray;
  font-weight: 600;
}

.carousel-item li span {
  font-weight: bold;
  color: #1a73e8;
}

/* Botões do carrossel */
/* .carousel-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 50%;
  z-index: 3; 
} */


.carousel-buttons-button-foward {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  border-radius: 5px;
  
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 45%;
  left: 90.5%;
  z-index: 1;
  height: 21vh;
  width: 6.5vw;
}

.carousel-buttons-button-prev {
  background-color: #1a73e8;
  color: #fff;
  border: none;
  border-radius: 5px;
  
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute;
  top: 45%;
  right: 90.5%;
  z-index: 1;
  height: 15vh;
  width: 6.5vw;
  height: 21vh;
}

.carousel-buttons button:hover {
  background-color: #0f5bbf;
}

.carousel-buttons button:focus {
  outline: none;
}

.patient-credits-title{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 3.8vh;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  
}

.patient-credits-title h4{
font-size: 1.4rem;

}


.wallet-content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-top: 8.5vh; */
  width: 80vw;
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  min-height: 27vh;
  padding: 10px;
  
}

.unlock-sub-title li {
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  letter-spacing: 0.20rem;
  font-size: 1.1rem;
  margin-bottom: 1vh;
}

@media (max-width: 768px) {
  .carousel-container {
    max-width: 100%;
  }

  .carousel-item {
    padding: 15px;
  }

  .carousel-buttons button {
    padding: 8px 15px;
    font-size: 0.9rem;
  }
}
