.planos-card-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 70vh;
  /* border: 1px solid red; */
}

.planos-card-second-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 90vw;

  height: 70vh;
}