@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {

  .home-cover {   
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    height: fit-content;
    background-image: none;
    background-size: 40vw 20vh;
    background-repeat: no-repeat;
     
  }
  
  .cover-img-container {
    display: flex;
  }

  .cover-img {
    display: flex;
    max-width: 99.9vw;
    /* height: 25vh; */
  }

  .whatsIcon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6vh;
    width: 100vw;
    background-image: linear-gradient(to bottom, rgba(0, 255, 0, 0.05), rgba(0, 255, 0, 0.2));
    border-radius: 2%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
  }
  
  .whatsIcon {
  width: 10vw;
  height: 10vh;
  }
  
  .contact-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 99.9vw;
    height: 35vh;
    background-color: aliceblue;
    border-radius: 0% 0% 0% 0%;
    box-shadow: none;
    /* border: 1px solid red; */
  }
  
  .contact-text { 
    font-family: 'Montserrat', sans-serif;
    font-size: 2.1rem;
    font-weight: 400;
    /* font-style: italic; */
    color: #4298bd;
    /* border: 1px solid red; */
  }
  
  .phone-number{
    color: rgb(26, 26, 26);
  }

}

/* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */