.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: fit-content;
  padding-top: 50px;
  background-color: aliceblue;
  /* border: 1px solid blue; */
  
}

.secondary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 95vw;
  height: fit-content;
  background-color: aliceblue;
  padding-bottom: 3vh;
  /* border: 1px solid green; */
  
}