@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@media only screen and (min-width: 601px) {
  .under-cover {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    height: fit-content;
    padding-top: 100px;
    background-color: rgb(238, 240, 240);

  
  }
  
  .under-cover-planos{
    display: flex;
    justify-content: center;
    max-width: 100vw;
    height: fit-content;
    padding:10vh 0 10vh 0;
    background-color: rgb(238, 240, 240);
  }
  
  .presentation-container {
    display: flex;
    width: 85vw;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid red; */
    
  }
  
  .presentation-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    max-width: fit-content;
    height: 25vh;
    /* border: 1px solid blue; */
  
  
  }
  
  .presentation-title {
    color: rgba(24, 24, 24, 0.742);
    font-size: 50px;
    font-weight: 550;
    font-family: 'Montserrat',sans-serif;
  }
  
  .presentation-text {
    color: rgb(69, 69, 69);
    font-size: 30px;
    font-weight: 300;
    font-family: 'Montserrat',sans-serif;
    font-style: italic;
  }
  
  .presentation-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 25px;
  }
  
  .aqui {
    text-decoration: none;
    color: rgb(69, 69, 69);
    font-size: 25px;
    font-weight: 300;
    font-family: sans-serif;
  }
  
  .aqui:hover {
    text-decoration: none;
    color: rgba(142, 15, 15, 0.742);
    font-size: 25px;
    font-weight: 300;
    font-family: sans-serif;
  }
  
  /* .images-container {
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width: 80vw;
    height: 45vh;
  } */

  .images-wrapper{
    padding-bottom: 6%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap:wrap;
    width: 80vw;
    height: 45vh;
  
  } 
  
  .clinic-images {
    padding: 10px;
    display: flex;
    width: 20vw;
    height: 25vh;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  
  .phone-number-planos {
    color: rgb(69, 69, 69);
    font-size: 30px;
    font-weight: 700;
    font-family: sans-serif;
    font-style: italic;
  }

  


}

