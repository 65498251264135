@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@media only screen and (min-width: 601px) { 
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 9vh;
  background-color: rgb(238, 240, 240);
  z-index: 2;
  
 
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 9vh;
  box-shadow: rgb(38, 57, 77) 0px 0px 30px 0px;
  background-color: rgb(238, 240, 240);
  position: fixed ;
  z-index: 2;
}
.logo-container {
  padding-left: 2.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.links-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50vw;
  height: 30vh;
  /* border: 1px solid red; */
}

.link-text {
  text-decoration: none;
  color: rgba(46, 46, 46, 0.742);
  font-family: 'Montserrat',sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
}

.link-text:hover {
  text-decoration: none;
  color: rgba(142, 15, 15, 0.742);
  /* font-family: sans-serif;
  font-weight: 800; */
}

.logo-img {
  /* height: 120px;
  width: 120px; */
  width: 5vw;
}
.logo-text {
  display: flex;
  color: rgba(46, 46, 46, 0.742);
  font-size: 2.5vw;
  font-weight: 400;
  letter-spacing: -2.5px;
}

.logo-text-media {
  display: none;

}
.logo-text:hover {
  display: flex;
  color: rgba(46, 46, 46, 0.742);
  font-weight: 600;
  letter-spacing: -2.5px;
}

.menu-btn {
  display: none;
}

}
