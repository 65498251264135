@media only screen and (max-width: 600px) {

.plano-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 35vh;

}

.plano-img {
width: 75vw;
height: 28vh;
}

}