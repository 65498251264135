.financeiro-infos {
  margin: 2vh;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 90%;
  

}

.toggle-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  width: 50vw;
  font-weight: 600;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.infos-container {
  margin-top: 15px;

}

.filter-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
 
}

.submit-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #218838;
}

.services-list {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1vh;
}

.services-list h3 {
  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2.2vh;
  width: 90vw;
  color: #007bff;
}


.services-list ul {
  list-style-type: none;
  padding: 0;
 
  
}

.services-list li {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 18vh;
  font-size: 1.1rem;
}

.date-title{
  margin-bottom: 1vh;
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  letter-spacing: 0.4rem;
  color: rgb(69, 69, 69);
}

.total-value {
  margin-top: 10px;
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
  color: rgb(211, 60, 60)
}

.historico-serviço-span{
  font-weight: 600;
  color: rgb(39, 38, 38);
}