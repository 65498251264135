@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {
* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: auto;
}

.cadastro-paciente-div {
  background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
  margin-top: 0.1vh;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.cadastro-paciente {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 2rem;
  font-weight: 600; 
} 

.cad-btns-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: 26vh;
  background-color: white;
  padding-top: 2vh;
}

.cad-dent-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  background-color: white;
  margin-top: 2vh;

  }
}