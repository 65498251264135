/* * {
  margin: 0;
  font-family: 'Arial', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
} */

.notice-board {
  margin-top: 3vh;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
  margin-bottom: 3vh;
}

.notice-title {
  font-size: 24px;
  color: #1e3a8a;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: bold;
}

.notice-content {
  background: #e0f2fe;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;
  font-size: 18px;
  color: #1e3a8a;
  font-weight: 500;
  animation: fadeIn 1s ease-in-out;
}

.notice-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notice-input {
  padding: 10px;
  border: 2px solid #60a5fa;
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  resize: none;
  height: 80px;
}

.notice-btn {
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.update-btn {
  background: #60a5fa;
  color: white;
}

.update-btn:hover {
  background: #2563eb;
  transform: scale(1.05);
}

.reset-btn {
  background: #f43f5e;
  color: white;
}

.reset-btn:hover {
  background: #e11d48;
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
