@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {
* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
 
  }

  .register-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    background-color: #f2f2f2;
    /* border: 1px solid black; */
    
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin-bottom: 3vh;
    margin-top: 1vh;
  }
  

.data-address-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}


.patient-data-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap; 
  width: 100vw;
  height: fit-content;
}

.dentist-data-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  
  
}

.patient-address-container {
display: flex;
justify-content: space-evenly;
align-items: center;
flex-direction: row;
flex-wrap: wrap;
margin-top: 3vh;
width: 100vw;
height: fit-content;
}

.patient-address-container-sec{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid  #f2f2f2;
  width: 100vw;
  height: fit-content;
  margin-bottom: 2.5vh;
  margin-top: 1vh;
}

.invi-div-mob-add { 
  width: 30vw;
  height: 4vh;
}

.invi-div-mob-data { 
  width: 45vw;
  height: 4vh;
}

.options-container {
 
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 25vw;
  /* width: 25vw;
 border-radius: 5%; */
  /* background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF); */
 
}

.options-container-sec{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 11vw; 
}

.options-container-sec-vip{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 5vw;

}



.dados-do-paciente {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  background-color: #f2f2f2;
  border-radius: 8px;
  margin-bottom: 1vh;
}

.dados-do-dentista {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
}


.form-input {
  width: 45vw;
  height: 4vh;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;

}

.form-input::placeholder {
text-align: center;
font-size: 0.9rem;
}

.input-check {
width: 20px;
height: 20px;

}


.check-text {
  font-weight: 700;
  color: rgb(27, 27, 27);
}

.cadastrar-btn-container {
  margin-bottom: 0.7vh;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.cad-dentist-btn {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(#464d55, #25292e);
  border-width: 0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-family: expo-brand-demi,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.cad-dentist-btn:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.cad-dentist-btn:active {
  outline: 0;
}

.cad-dentist-btn:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .cad-dentist-btn {
    height: 48px;
  }
  }
}

