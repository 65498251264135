@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {


  /* .quee-entry-form-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .quee-entry-form{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 40vh;
    margin-top: 5vh;
    margin-bottom: 8vh;
    
  } */


  .quee-entry-btn {

      align-items: center;
      appearance: none;
      background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
      border: 0;
      border-radius: 6px;
      box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: inline-flex;
      font-family: "JetBrains Mono",monospace;
      height: 48px;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow .15s,transform .15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow,transform;
      font-size: 18px;
    }
    
    .quee-entry-btn:focus {
      box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    }
    
    .quee-entry-btn:hover {
      box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
      transform: translateY(-2px);
    }
    
    .quee-entry-btn:active {
      box-shadow: #3c4fe0 0 3px 7px inset;
      transform: translateY(2px);
    }

    .input-doc {
      /* margin-top: 5vh; */
      width: 70vw; /* largura ajustável conforme necessário */
      height: 40px; /* altura ajustável conforme necessário */
      
      border-radius: 8px; /* bordas arredondadas */
      /* border: 1px solid #6dc6ff;  */
      background-color: #c9e6ff; /* fundo azul claro */
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* sombra suave */
      transition: border-color 0.3s ease; /* transição suave da cor da borda */
      font-size: 16px;
     

  }
  
  /* Estilo ao focar */
  .input-doc:focus {
      outline: none; /* removendo o contorno ao focar */
      border-color: #4189ff; /* mudando a cor da borda ao focar */
  }

  .input-doc::placeholder {
    text-align: center;
    font-size: 1.9vh;
    color: #999; /* Cor do texto do placeholder */
    font-style: italic; /* Estilo do texto do placeholder */
    opacity: 0.7; /* Opacidade do texto do placeholder */
    
}

/* Estilo Principal */
.quee-entry-form-main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f7fa;
  margin-top: 3vh;
  width: 100%;
}

.quee-entry-form {
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

/* Campo de texto */
.input-doc {
  width: 100%;
  padding: 10px 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
  transition: border-color 0.3s;
}

.input-doc:focus {
  border-color: #007bff;
  outline: none;
}

/* Categoria: Manutenção */
/* .quee-entry-category label {
  display: block;
  font-size: 0.95rem;
  color: #333;
  margin-bottom: 10px;
} */

.manutencao{
  font-size: 1.15rem;
}

.categories{
  font-size: 1rem;
}

.limpeza {
  font-size: 1.15rem;
}

.reparo{
  font-size: 1.15rem;
}

.repairs {
  font-size: 1rem;
}

.sim-nao{
  font-size: 1rem;
}
.quee-entry-category > div {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  gap: 10px;
  margin-top: 10px;
 
  
  
}

.quee-entry-category label input {
  margin-right: 5px;
  
}

.quee-entry-cleaning label input {
  margin-right: 5px;
  
}

.quee-entry-repair label input {
  margin-right: 5px;
  
}


/* Botão de checkbox */
.quee-entry-category label {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 1px solid rgb(255, 98, 0);
  min-width: 47%;

}

.quee-entry-category p {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  border: 1px solid rgb(255, 98, 0);
  
}

.quee-entry-repair label{
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  border: 1px solid rgb(255, 200, 0);

}

.quee-entry-cleaning label {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(0, 187, 255);
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  width:47%;
  
}

.quee-entry-cleaning p {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  justify-content: center;
  transition: all 0.3s ease;
  margin-bottom: 10px;
  border: 1px solid rgb(0, 187, 255);
}

.quee-entry-category label:hover,
.quee-entry-repair label:hover,
.quee-entry-cleaning label:hover {
  background-color: #f0f8ff;
  border-color: #007bff;
}

input[type="checkbox"]:checked + label {
  background-color: #cce4ff;
  border-color: #007bff;

}

/* Limpeza */
.quee-entry-cleaning {
  margin: 20px 0;

  
}



.cleaning-sub-div{
display: flex;
justify-content: space-between;
align-items: center;
}

.stand-smart-group{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vip-special-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .manutencao-sub-div{
display: flex;
justify-content: space-between;
align-items: center;
} */

/* Reparo */
.quee-entry-repair {
  margin-bottom: 20px;
}

.quee-entry-repair > div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.error-message-container {
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: auto;
margin-bottom: 2vh;
font-size: 2rem;
}


.error-message-quee-entry {
  color: red;
 font-size: 1.2rem;
  font-family: sans-serif;
}


/* Estilo para o container do checkbox */
.use-titular-cred-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  height: 4vh;  
  text-align: center;
}

/* Estilo para o checkbox */
.use-titular-cred-checkbox input[type="checkbox"] {
  appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid #007bff;
  border-radius: 4px;
  margin-right: 8px;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.use-titular-cred-checkbox input[type="checkbox"]:checked {
  background-color: #007bff;
  border-color: #0056b3;
}

.use-titular-cred-checkbox input[type="checkbox"]:checked::after {
  content: '✔';
  display: block;
  text-align: center;
  color: #fff;
  font-size: 1rem;  
}

/* Ajuste de foco */
.use-titular-cred-checkbox input[type="checkbox"]:focus {
  box-shadow: 0 0 3px 2px rgba(0, 123, 255, 0.5);
}

/* Estilo para o texto ao lado do checkbox */
.use-titular-cred-checkbox span {
  font-size: 1rem;
  
}

.use-titular-cred-checkbox label {
  display: flex;
  justify-content: center;
  align-items: center;
  
}



/* Responsividade */
@media (max-width: 480px) {
  .quee-entry-form {
    padding: 15px;
  }

  .input-doc {
    font-size: 0.9rem;
    padding: 8px;
  }

  .quee-entry-category label,
  .quee-entry-cleaning label,
  .quee-entry-repair label {
    font-size: 0.85rem;
    padding: 8px;
  }
}

  

}
