* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

  .dentist-profile-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: white;
  }
.welcome-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
  margin-bottom: 2vh;
  width: 100%;
  background-color: burlywood;
}

  .welcome {
    font-size: 1.4rem;
    font-family: sans-serif;
    color: rgb(34, 33, 33);

  }