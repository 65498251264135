@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
  }

.register-form-container-sec-ort{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column; 
  min-height: 150vh;
  width:100vw ;
  
}

.text-box-container-ort-cad{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  height: 12.5vh;
}


.field-form-ort-cad{
 
  background-color: rgba(0, 157, 255, 0.158);
  width: 90%;
  height: 7.5vh;
  border-bottom:1px solid rgba(46, 46, 46, 0.941);
  font-size: 1.2rem;
  
}

.field-form-ort-cad::placeholder{
  font-size: 1.2rem;
  letter-spacing: 0.05rem;
  text-align: center;
  font-style: italic;
  color: rgba(46, 46, 46, 0.697)
  
}