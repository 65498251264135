@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
 
  }

  .auth-main-ort{
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 30vh;
    width: 100vw;
    background-image: linear-gradient(to bottom, #5adaff, #5468ff);
  }

  .auth-cover-text-box-ort {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 15vh;
    width:90vw ;
  
  }

  .bem-vindo-ort{
    font-size: 2.2rem;
    margin-bottom: 0.5vh;
    /* font-weight: 500; */
    color: white
  }

  .acesse-ort{
    margin-top: 0.5vh;
    font-size: 1.4rem;
    color: rgba(46, 46, 46, 0.745)
  }