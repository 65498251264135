@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.map-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* border: 1px solid green; */
  max-width: 100vw;
  height: fit-content;
  padding: 5vh 0 5vh 0
}

.go-back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.go-back-button:hover {
  background-color: #0056b3;
}


.map {
  display: flex;
  /* border: 3px ridge gray; */
  width: 60vw;
  height: 40vh;
  /* padding: 5px; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.map-infos-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  width: 60vw;
  height: fit-content;
  font-size: 2rem;
  font-weight: 500;
  font-family:'Montserrat', sans-serif;
  font-style: italic;
  color: rgb(41, 41, 41);
  /* background-color: rgba(238, 240, 240, 0.779); */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

}

.map-contato-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  /* border: 1px solid blue; */
  width: 50vw;
  height:fit-content;
}

.map-w-icon {
  width: 1.5vw;
}

.como-chegar {
  font-size: 1.5rem;
  padding: 3vh;
  font-family:'Montserrat', sans-serif;
}

/* .whatsIcon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6vh;
  width: 60vw;
  background-image: linear-gradient(to bottom, rgba(0, 255, 0, 0.05), rgba(0, 255, 0, 0.2));
  border-radius: 2%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
} */

.whatsIcon {
  width: 6vw;
  height: 6vh;
  }
  