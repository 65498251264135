@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.home-cover {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  max-width: 100vw;
  height: 70vh;
  background-image: url('../../../assets/images/home-cover-blank.jpg');
  background-size: 80vw 70vh;
  background-repeat: no-repeat;
  
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */

}

.cover-img {
  display: none;
}

.whatsIcon-container {
  height: 80px;
}

.whatsIcon {
width: 80px;
height: 80px;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* width: 30vw; */
  width: 450px;
  background-color: aliceblue;
  border-radius: 10% 0% 0% 10%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-text {
 
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  /* font-size: 55px; */
  font-weight: 500;
  color: #4298bd
}

.phone-number{
  color: rgb(26, 26, 26);
  
}