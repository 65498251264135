* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

  .main-cad-pat {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 150vh;
    width: 100vw;
  }
