@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&display=swap');
@media only screen and (max-width: 600px) {

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 9vh;
    z-index: 2;
    /* background-color: rgb(238, 240, 240); */
   
 
  }
  .header-container {
    padding: 0 1vh 0 1vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height: 9vh; 
    position: fixed; 
    background-color: rgb(238, 240, 240);
    box-shadow: rgb(38, 57, 77) 0px 0px 30px 0px;
    z-index: 2;
  }

  .logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

   
  }
  .logo-text {
    display: none;
  }
  
  .logo-text-media {
    margin-right: 4.2%;
    font-family: 'PT Sans', sans-serif;
    display: flex;
    color: rgba(46, 46, 46, 0.742);
    font-size: 3.2vh;
    font-weight: 400;
    letter-spacing: -2.0px;

  }

  .links-container {
    display: none;
  }

  .menu-btn {
    display: flex;
    width: 12vw;
  

  }

  .logo-img {
    width: 18vw;
 
  }
}
