.dentist-selector-main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 25vh;
  width: 90vw;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
   rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
   margin-bottom: 10px;
}

.dentist-selector-content-first{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  min-height: 25vh;
}

/* .selects-btn-alocar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 15vh;
  border: 1px solid red;
} */

.dentist-selector-content-final{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  min-height: 25vh;
  
}

.qnt-de-consultorios-p{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  font-size: 1.30rem;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  width: 90vw;
  height: 4vh;
  color: aliceblue;
}

.qnt-consultorios-select{
  height: 3.5vh;
  width: 25vw;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 5%;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
  rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  /* background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%); */
}

.select-alocacao {
  height: 3.5vh;
  width: 52vw;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  border-radius: 5%;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
  rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

/* .alocar-btn {
  width: 62vw;
  font-size: 15px;
  height: 3vh;
  color: aliceblue;
  background-color: green;
} */


.alocar-btn  {
  appearance: none;
  backface-visibility: hidden;
  background-color: #27ae60;
  border-radius: 8px;
  border-style: none;
  box-shadow: rgba(39, 174, 96, .15) 0 4px 9px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: Inter,-apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
  outline: none;
  overflow: hidden;
  padding: 8px 15px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
  width: 30vw;
  
}

.alocar-btn:hover {
  background-color: #1e8449;
  opacity: 1;
  transform: translateY(0);
  transition-duration: .35s;
}

.alocar-btn:active {
  transform: translateY(2px);
  transition-duration: .35s;
}

.alocar-btn:disabled {
background-color: gray;
box-shadow: none;
}

/* .alocar-btn:hover {
  box-shadow: rgba(39, 174, 96, .2) 0 6px 12px;
} */


.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, 
  rgba(0, 0, 0, 0.12) 0px -12px 30px,
   rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
     rgba(0, 0, 0, 0.09) 0px -3px 5px;  
 }

 .modal-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 16.5px;
 }

 .modal-btns-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
}

 .dentist-consultorio-li {
  display: flex;
  justify-content: space-around;
  align-items:flex-end;
  font-size: 1rem;
  color: rgb(65, 64, 64);
  margin-bottom: 2.1vh;
  /* border: 1px solid blue; */
  width: 80vw;
  font-weight: 600;
  /* font-style: italic; */
  
  
 }

 .p-dentist-consultorio{
  border-bottom: 1px solid gray;
  width: 50vw;
  
 }

 .remover-btn {
  color: rgb(255, 46, 46);
  font-size: 0.85rem;
  margin-left: 10px;
  padding: 4px;
  /* box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px; */
  font-weight: 600;
  letter-spacing: 1.8px;
  border-radius: 5%;
  /* background-color: rgb(208, 208, 208); */
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  
 }

.reiniciar-btn {
  width: 50vw;
  padding: 4px;
  font-size: 0.9rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 5%;
  font-weight: 600;
  color: rgb(255, 46, 46);
  letter-spacing: 1.8px;
}

.modal-btn-confirm {

  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.modal-btn-confirm:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.modal-btn-confirm:hover {
  background-color: #2c974b;
}

.modal-btn-confirm:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.modal-btn-confirm:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.modal-btn-confirm:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.modal-btn-cancel {
appearance: none;
background-color: #a42e2e;
border: 1px solid rgba(27, 31, 35, .15);
border-radius: 6px;
box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
box-sizing: border-box;
color: #fff;
cursor: pointer;
display: inline-block;
font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
font-size: 14px;
font-weight: 600;
line-height: 20px;
padding: 6px 16px;
position: relative;
text-align: center;
text-decoration: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
white-space: nowrap;
}

.modal-btn-cancel:focus:not(:focus-visible):not(.focus-visible) {
box-shadow: none;
outline: none;
}

.modal-btn-cancel:hover {
background-color: #2c974b;
}

.modal-btn-cancel:focus {
box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
outline: none;
}

.modal-btn-cancel:disabled {
background-color: #94d3a2;
border-color: rgba(27, 31, 35, .1);
color: rgba(255, 255, 255, .8);
cursor: default;
}

.modal-btn-cancel:active {
background-color: #298e46;
box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}