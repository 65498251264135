.manual-credits-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  font-family: Arial, sans-serif;
}

.verify-patient-form,
.add-credits-form {
  margin-bottom: 20px;
}

.verify-patient-title,
.add-credits-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.verify-patient-input,
.add-credits-input,
.verify-patient-button,
.add-credits-button,
.add-credits-select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.verify-patient-button,
.add-credits-button {
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.verify-patient-button:disabled,
.add-credits-button:disabled {
  background-color: #ccc;
}

.verify-patient-button:hover:not(:disabled),
.add-credits-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.verify-patient-error {
  color: red;
  font-size: 12px;
}

.add-credits-label {
  font-size: 14px;
  margin-bottom: 5px;
  display: block;
}
