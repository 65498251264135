@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  height: 25vh;
  background-color: #242424;

}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 70vw;
  height: 10vh;

  /* border: 1px solid red; */
}

.cro-container {
  display: flex;
  max-width: 15vw;
  height: 10vh;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
 
}

.cro-text {
  color: rgb(238, 236, 236);
  font-weight: 600;
  font-family: 'Montserrat',sans-serif;
  font-size: 0.9vw;
}

