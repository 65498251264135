
@media only screen and (max-width: 600px) {
.planos-card-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  max-width: 100vw;
  height: fit-content;
  padding-top: 0;
 

}

.planos-card-second-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 95vw;
  height: fit-content;
  background-color: none;
}
}