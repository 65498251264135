@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.treatment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* border-radius: 5%; */
  width: 20vw;
  height:50vh;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  /* background-color:rgba(138, 138, 138, 0.229); */
  /* border: 1px solid red; */
 
}

.treatment-title {
  font-family: sans-serif;
  font-size: 1.5vw;
  font-style:italic;
  font-weight: 600;
  color: rgb(54, 54, 54);
  color: rgb(244, 109, 109);
  padding: 1px;

}


.treatment-description {
  font-family:'Montserrat', sans-serif;
  font-size: 1rem;
  font-style:italic;
  font-weight: 520;
  color: rgb(54, 54, 54);
  /* color: aliceblue; */
  padding: 2px;
  width: 18vw;
}


.treatment-image {
  height: 20vh;
  width: 20vw;
}

.clr-treatment-image {
  height: 20vh;
  width: 10vw;
}

.impl-treatment-image {
  height: 20vh;
  width: 14vw;
}

.lmp-treatment-image {
  height: 20vh;
  width: 9.5vw;
}

.rst-treatment-image {
  height: 20vh;
  width: 9.5vw;
}