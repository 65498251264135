
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) { 
  * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0;
    font-family: 'Montserrat', sans-serif;
    }


  .products-card-main-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    min-height: 180vh;
    width: 100% ;
    height: auto;
  }

  .repair-card-main-container {
    margin-top: 3vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }


  .products-card-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    min-height: 20vh;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
     rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    
  }

  .products-card-sub-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    min-height: 40vh;
    margin-top: 2.5vh;
  }

  .product-infos-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 8vh;
    background: linear-gradient(135deg, #b8743d, #cd7f32, #d89a6a);
    margin-bottom: 20px;
    
  }


  .product-infos-container-repair{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 8vh;
    /* background: linear-gradient(135deg, #b8743d, #cd7f32, #d89a6a); */
    margin-bottom: 20px;
    background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0);

    
  }

  .product-infos-container-smart{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 8vh;
    background: linear-gradient(135deg, #0073ffe1, #00c8ffe6);
    margin-bottom: 20px;
    
  }


  .product-infos-container-vip{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 8vh;
    background: linear-gradient(135deg, #b38600, #ffcc00);
    margin-bottom: 20px;
 
   }

    .product-infos-container-special{
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      width: 100%;
      min-height: 8vh;
      background: linear-gradient(135deg, #e0e0e0, #f8f9fa, #cce7f0);
      margin-bottom: 20px;

      }

  .product-infos-sub-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
   
  }


  .product-info-value {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
    width: 60%;
    height: 7.5vh;
    font-size: 1.1rem;
    color: #f8f9fa;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    background: linear-gradient(135deg, #4d4d4d8b, #1c1c1c); 
  }

  .standard-title-box{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background: linear-gradient(135deg, #b8743d, #cd7f32, #d89a6a);

  }


  .repair-title-box{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    width: 90vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    /* background: linear-gradient(135deg, #b8743d, #cd7f32, #d89a6a); */
    background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0);
  }


  .smart-title-box{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(135deg, #666666, #d9d9d9);
    background: linear-gradient(135deg, #0073ffe1, #00c8ffe6);
    width: 90vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .vip-title-box{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(135deg, #b38600, #ffcc00);
    width: 90vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .special-title-box{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(135deg, #e0e0e0, #f8f9fa, #cce7f0);
    width: 90vw;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .box-title-font{
    color:  rgba(25, 24, 24, 0.873);
    color: whitesmoke;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.3rem;
    
  }

  .box-title-font-repair{
    color:  rgba(48, 48, 48, 0.873);
    /* color: whitesmoke; */
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.3rem;
    
  }

  .box-title-font-special{
    color:  rgba(25, 24, 24, 0.873);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
    letter-spacing: 0.3rem;
    
  }

  .special-font-color{
    color: rgba(25, 24, 24, 0.873);
  }

}