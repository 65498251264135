@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {

  #under-section {
    display: flex;
    height: fit-content;
  }

  .under-cover {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 115vh;
    padding-top: 2.5vh;
    background-color: white;
  
  }
  
  .under-cover-planos{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    height:fit-content;
    padding-top: 2vh;
    background-color: rgb(238, 240, 240);
    padding-bottom: 5vh;

   
  }

  .whats-container-planos{
    padding-bottom: 2.2vh;
  }
  
  .presentation-container {
    display: flex;
    width: 85vw;
    height: fit-content;
    justify-content:space-between;
    align-items: center;
    flex-direction: column;
  
    
  }
  
  .presentation-text-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding-top: 2vh;
    width: 85vw;
    height: 35vh;
    /* border: 1px solid green; */
  
  
  }
  
  .presentation-title {
    display: flex;
    color: rgba(24, 24, 24, 0.742);
    font-size: 2.0rem;
    font-weight: 700;
    font-family: 'Montserrat',sans-serif;
    /* border: 1px solid red; */
  }
  
  .presentation-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(69, 69, 69);
    font-size: 1.4rem;
    width: 85vw;
    font-weight: 300;
    font-family:'Montserrat',  sans-serif;
    font-style: italic;
    /* border: 1px solid black; */
    
  }
  
  .presentation-links-container {
    display: flex;
    width: 85vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 3vh;
  }
  
  .aqui {
    display: flex;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 400;
    font-family: sans-serif;
    color: rgba(142, 15, 15, 0.742);
  }
  
  .images-container {
    padding-top: 2vh;
    padding-bottom: 3vh;
    position: relative;
    display: flex;
    /* overflow: hidden; */
    justify-content: center;
    width: 100vw;
    align-items:center;  
  }

  
  .images-wrapper {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory; 
  }

  .images-wrapper::after,
  .images-wrapper::before {
  content: '\3008';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  pointer-events: none;
  opacity: 0.4;
  font-size:1.5rem;
  font-weight: 700;
}

.images-wrapper::after {
  content: '\3009';

}

.images-wrapper::before {
  left: 0px;
}

.images-wrapper::after {
  right: 0px;
}


  .images-wrapper img{
    box-shadow: none;
    padding: 8px;
    flex-shrink: 0;
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    scroll-snap-align: start;
    
  }

  /* #photo1, #photo2, #photo3 {
    width: 100vw
  } */

  .whats-text-planos{
  font-size: 1.5rem;
  }

  .phone-number-planos {
    color: rgb(69, 69, 69);
    font-size: 1.5rem;
    font-weight: 700;
    font-family: sans-serif;
    font-style: italic;
  }

}