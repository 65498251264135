@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

.register {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cadastro-paciente-div {
  background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
  margin-top: 0.1vh;
  width: 80vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.cadastro-paciente {
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 2.7rem;
  font-weight: 500; 
}

.cad-dep-form-sub-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.cad-btns-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  height: 20vh;
  background-color: white;

}

.cad-dent-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
  background-color: white;
  margin-top: 2vh;

}

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.2em;
  color: #555;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
