@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {

  * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
    }


    .people-card-container{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
     margin-top: 2vh;
     
    }
/* 
    .people-card {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      border: 1px solid blue;
      margin-top: 1rem;
      margin-bottom: 1rem;
    } */

    .people-card {
      
      width: 100%;
      min-height: 9vh;
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: row;
      background-color: white;
      position: relative; /* Adiciona uma posição relativa para a div */
     
    }
    
    .people-card ::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15%; /* Ajusta a posição inicial da borda para 10% */
      width: 75%; /* Define a largura da borda como 80% */
      height: 0.13rem;
      background-color: #87CEEB;
    }

    /* .position-1 {
      color: red;
    }

    .position-2 {
      color: yellow;
    }

    .position-3 {
      color: green;
    } */

  

    .perfil-icon {
      width: 5vh;
      height: 5vh;
    }

    .people-place-container {
      font-family: 'Montserrat', sans-serif;;
      display: flex;
      flex-direction: row;
      justify-content:space-between;
      align-items: center;
      /* border: 1px solid black; */
      width: 70vw;
     
    }

    .person-quee-name {
      font-size:1rem;
      font-weight: 600;
      color:rgba(36, 36, 36, 0.921);
      font-style: italic;
    }

    .number-quee {
      font-size:3vh;
      font-style: italic;
      margin-left: 5vw;
      color: #6dc6ff; /* Azul claro */
      text-shadow: 0 0 3px #add8e6, 0 0 6px #add8e6, 0 0 9px #add8e6, 0 0 12px #6dc6ff, 0 0 18px #6dc6ff, 0 0 24px #6dc6ff, 0 0 33px #6dc6ff, 0 0 45px #6dc6ff;
    }

    .name-red {
      color:rgba(254, 21, 21, 0.795);
      font-size:1rem;
      font-weight: 600;
      font-style: italic;
    }

    .fila-convencional {
      font-size: 1.4rem;
      
      letter-spacing: 2.5px;
      color: rgb(59, 59, 59);
      font-style: italic;
    }

    .fila-vip {
      font-size: 1.4rem;
      letter-spacing: 2.5px;
      color: rgb(44, 44, 44);
      font-style: italic;
      
    }

    .fila-title-div {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 5vh;
      margin-bottom: 2.5vh;
      background: linear-gradient(135deg, rgba(173, 216, 230, 0.5), rgba(135, 206, 235, 0.8));
    }

    .fila-vip-title-div {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 5vh;
      margin-bottom: 2.5vh;
      background: linear-gradient(135deg, rgba(255, 215, 0, 0.3), rgba(255, 223, 0, 0.7));
    }

    .person-and-document{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
     
    }

    .person-quee-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      width: 17vw
    }
}
