@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
  }

.search-patients-container{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 80vw;
  min-height: 30vh;
  background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
}

.busca-pacientes-title{
  color: white;
  font-size: 2.4rem;
  font-weight: 500;
}

.input-search-container {
  
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 80vw;
    background-color: #f2f2f2;
    min-height: 20vh;
  
}

.search-form-input {
  width: 20vw;
  height: 4vh;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
}

.search-form-input::placeholder {
text-align: center;
font-size: 0.9rem;
}

.search-register-card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  color: #f2f2f2;
  border: 2px white;
  border-style: dashed;
  width: 79vw;
  min-height:15vh ;
  font-size: 1.4rem;
  margin-top: 1vh;
  margin-bottom: 5vh;
   
}

.search-li-container {
  /* border: 1px solid black; */
}

.patient-personal-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-top: 1vh;
  width: 78.9vw;
  min-height: 16vh;
}

.personal-sub-box {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 12vh;
  /* min-width: 25vw; */
 
}

.sub-box-tag{
  font-weight: bold;
  font-size: 1.3rem;
  
}

.sub-box-tag-text{
  font-size: 1.2rem;
  /* font-weight: 600; */
  color: rgb(101, 101, 101);
}

.patient-contact-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  min-height: 10vh;
  width: 78.9vw;
}

.patient-contact-sub-box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8vh;
  /* min-width: 25vw; */
  
}

.patient-address-box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  width: 78.9vw;
}

.patient-address-box-sec{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 78.9vw;
}

/* .address-sub-box-invi{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 16vh;
  width: 18vw ;
  border: 1px solid red;
} */

.address-sub-box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 19vh;
  max-width: 30vw;
  margin-top: 1vh;
  margin-bottom: 1vh;

  

}

.patient-plan-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 78.9vw;
}

.patient-plan-box-sec{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 78.9vw;
}

.plans-sub-box {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
 
  width: 22vw;
}

.sub-title-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 78.6vw;
  background-color: #cccccc6f;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;

}

.sub-title-text {
  display: flex;
  justify-content: flex-start;
  align-items:flex-start;
 
  width: 10vw;
}

.sem-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79vw;
  height: 6vh;
  color: rgb(101, 101, 101);
}