.plano-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 20vw;
  height: 20vh;

}

.plano-img {
width: 10vw;
height: 20vh;
}