.patient-inservice-main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 90vw;
}

.consultorio-card{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  width: 90vw;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
  rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  margin-bottom: 2vh;
  margin-top: 2vh;
}

.card-conclude-cancel-btns-container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 80vw;
  margin-top: 2vh;
  margin-bottom: 1vh;
  
}

.card-cancel-btn {
  background-color:#FF6347;
  color: aliceblue;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 0.9rem;
}

.card-conclude-btn {
  background-color:#4CAF50 ;
  color: aliceblue;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-family: sans-serif;
  font-size: 0.9rem;
}

.consultorio-card-title {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: sans-serif;
  font-size: 1.2rem;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  width: 90vw;
  min-height: 4vh;
  color: aliceblue;
}

.consultorio-card-patient-age {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: sans-serif;
  font-size: 1rem;
  width: 90vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  background-color: wheat;
  padding-top: 4px;
  padding-bottom: 4px;
  color: rgb(42, 42, 42);
}

.patient-services-infos {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  font-family: sans-serif;
  font-size: 1rem;
  width: 70vw;
  height: 10vh;
}


.form-treatment-sheet {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
  width: 90vw;
  
  font-family: 'Arial', sans-serif;
}

.form-treatment-sheet-h4 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.form-treatment-sheet label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 15px;
  font-weight: bold;
  color: #555;
}

.form-treatment-sheet label input,
.form-treatment-sheet label textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.form-treatment-sheet label input:focus,
.form-treatment-sheet label textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.form-treatment-sheet textarea {
  resize: vertical;
  min-height: 80px;
}

.form-treatment-sheet button {
  background-color: #007BFF;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  width: 100%;
  max-width: 200px;
}

.form-treatment-sheet button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.form-treatment-sheet button:hover:not(:disabled) {
  background-color: #0056b3;
}

.form-treatment-sheet p {
  color: red;
  margin-bottom: 10px;
  font-size: 14px;
  text-align: center;
}

/* .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, 
  rgba(0, 0, 0, 0.12) 0px -12px 30px,
   rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px,
     rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
 } */

 .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* Mantém o modal fixo na tela */
  top: 50%; /* Centraliza verticalmente */
  left: 50%; /* Centraliza horizontalmente */
  transform: translate(-50%, -50%); /* Ajusta para o centro */
  width: 80vw;
  height: 20vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, 
              rgba(0, 0, 0, 0.12) 0px -12px 30px,
              rgba(0, 0, 0, 0.12) 0px 4px 6px,
              rgba(0, 0, 0, 0.17) 0px 12px 13px,
              rgba(0, 0, 0, 0.09) 0px -3px 5px;
  z-index: 1000; /* Garante que o modal fique acima de outros elementos */
  background: white; /* Fundo do modal */
  border-radius: 8px; /* Bordas arredondadas */
  overflow: hidden; /* Impede elementos extras de "sair" do modal */
}

 .modal-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 16.5px;
 }

 .modal-btns-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
}

.modal-btn-confirm {

  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, .15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
}

.modal-btn-confirm:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.modal-btn-confirm:hover {
  background-color: #2c974b;
}

.modal-btn-confirm:focus {
  box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
  outline: none;
}

.modal-btn-confirm:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, .1);
  color: rgba(255, 255, 255, .8);
  cursor: default;
}

.modal-btn-confirm:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


.modal-btn-cancel {
appearance: none;
background-color: #a42e2e;
border: 1px solid rgba(27, 31, 35, .15);
border-radius: 6px;
box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
box-sizing: border-box;
color: #fff;
cursor: pointer;
display: inline-block;
font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
font-size: 14px;
font-weight: 600;
line-height: 20px;
padding: 6px 16px;
position: relative;
text-align: center;
text-decoration: none;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: middle;
white-space: nowrap;
}

.modal-btn-cancel:focus:not(:focus-visible):not(.focus-visible) {
box-shadow: none;
outline: none;
}

.modal-btn-cancel:hover {
background-color: #2c974b;
}

.modal-btn-cancel:focus {
box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
outline: none;
}

.modal-btn-cancel:disabled {
background-color: #94d3a2;
border-color: rgba(27, 31, 35, .1);
color: rgba(255, 255, 255, .8);
cursor: default;
}

.modal-btn-cancel:active {
background-color: #298e46;
box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
}


