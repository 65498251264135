* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

  .tratamentos {
    display: flex;
    justify-content: space-between;
    align-items: space-around;
    flex-direction: column;
    /* height: 100vh;
    max-width: 100vw; */overflow-x: hidden;
  }