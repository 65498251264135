.patient-history-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 90%;
  background-color:#fff;
  margin-top: 2vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 2vh;
  margin-top: 8vh;
}

.patient-history-container-transa {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  width: 90%;
  background-color:#fff;
  margin-top: 2vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 2vh;
  
}



.transa-title-div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  height: 3.8vh;
  /* background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0); */
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);

}

h2 {
  color: #444343;
  font-size: 1.3rem;
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
 
}

.filter-container label {
  display: flex;
  flex-direction: column;
  font-size: 14px;
 
 
}

input[type='date'] {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
 
}

.carousel-container-services {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  gap: 10px;
  width: 80vw;
}

.card {
  flex: 0 0 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  max-width: 350px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background: #fff;
  scroll-snap-align: center;
  text-align: center;
  /* background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0); */
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
 
}

.card-services {
  flex: 0 0 80%;
  max-width: 300px;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background: #fff;
  scroll-snap-align: center;
  text-align: center;
  
}


.card p {
  margin: 5px 0;
  color: #555;
  font-size: 1rem;
}
.card-services p {
  margin: 5px 0;
  color: #555;
  font-size: 1.1rem;
}

.no-services {
  color: #888;
  font-size: 16px;
}

.card strong {
  color: #222;
}

.card-services strong {
  color: #222;
}
/* @media (max-width: 768px) {
  .card {
    flex: 0 0 90%;
  }

  .filter-container {
    flex-direction: column;
    align-items: center;
  }
} */
