@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

  .adm {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  

  }

  body {
    background-color: aliceblue;
  }

  .control-painel {
    background-color: white;
    margin-top: 0.1vh;
    margin-bottom: 5vh;
    font-family: 'Montserrat', sans-serif;
   
    /* color: rgba(40, 40, 40, 0.919); */

    
  }

  .painel-de-controle-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #87CEEB;
    background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
    height: 10vh;
  }

  .painel-de-controle {
    font-size: 2.7rem;
    font-weight: 500;
    color: white;
  }

  .adm-btns-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 80vw;
    height: 20vh;

  }

  .adm-btns {

    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono",monospace;
    height: 48px;
    width: 14vw;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .adm-btns:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .adm-btns:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .adm-btns:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }

  