@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

  * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
    }


.quee-uper-cover {
  
  background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* height: 18vh; */

    }

    .orto-img-quee {
      width: 50vw;
      height: 20vh;
      
    }

    .fila-virtual {
      margin-top: 1.3vh;
      font-family: 'Montserrat', sans-serif;
      color: #ffffff; /* Cor do texto branca */
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
      animation: brilho 1.5s infinite alternate; /* Sombra do texto */
      letter-spacing: 5px;
      
     
    }

    @keyframes brilho {
      0% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.955); /* Brilho forte */
      }
    
      100% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* Brilho fraco */
      }
    }

  

  .container-left {
    position:absolute;
    width: 6vw; /* Largura da área onde o círculo se moverá */
    height: 10vh; /* Altura da área onde o círculo se moverá */
    left: 0;
    margin-left: 12vw
  }

  .circle-l {
    position: absolute;
    top: 50%; /* Alinha o círculo verticalmente ao meio da área */
    left: 0; /* Inicia o círculo na extrema esquerda da área */
    width: 30px; /* Largura do círculo */
    height: 30px; /* Altura do círculo */
    /* background-color: white; */
    /* border-radius: 50%;  */
    animation: moveUpDown-l 5s infinite alternate; /* Aplica a animação */
  }
  
  @keyframes moveUpDown-l {
    0% {
      transform: translateY(-100px); /* Começa na posição inicial */
    }
    100% {
      transform: translateY(75px); /* Move para cima */
    }
    
    
  }

  .container-rigth {
    position:absolute;
    width: 6vw; /* Largura da área onde o círculo se moverá */
    height: 10vh; /* Altura da área onde o círculo se moverá */
    right: 0;
    margin-right: 10vw
  }

  .circle-r {
    position: absolute;
    top: 50%; /* Alinha o círculo verticalmente ao meio da área */
    left: 10; /* Inicia o círculo na extrema esquerda da área */
    width: 30px; /* Largura do círculo */
    height: 30px; /* Altura do círculo */
  /* Cor do círculo */
    /* border-radius: 53% 47% 49% 51% / 93% 94% 6% 7% ; */
    animation: moveUpDown-r 5s infinite alternate; /* Aplica a animação */
  }

  @keyframes moveUpDown-r {
    0% {
      transform: translateY(75px); /* Começa na posição inicial */
    }
    100% {
      transform: translateY(-100px); /* Move para cima */
    }
    
    
  }

  