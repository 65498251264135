.patient-profile-main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: auto;

}

.loja-virtual {
  margin-top: 5vh;
  
}

.loja-virtual-header{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-size: 1.5rem;
  font-family: sans-serif;
  color: rgb(56, 56, 56);
  
  /* background: linear-gradient(135deg, #b8e994, #a7e4d1, #d1d8e0); */
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  height: 4vh;
  /* background: linear-gradient(
    90deg, 
    #b87333,
    #add8e6, 
    #ffd700, 
    #e4f1f9 
  ); */
}

.loja-virtual-content {
  overflow: hidden;
  max-height: 0; /* Início com altura 0 */
  transition: max-height 1.2s ease-in-out; /* Transição suave */
 
}

.loja-virtual-content.open {
  max-height: 4000px;
}

.loja-virtual-content.closed {
  max-height: 0; /* Fechado */
}



.history{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 5vh;
  width: 100vw;
  margin-bottom: 5vh;
  color: rgb(56, 56, 56);
}

.history-header{
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  width: 100vw;
  font-size: 1.5rem;
  font-family: sans-serif;
}

.history-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 0; /* Início com altura 0 */
  transition: max-height 1s ease-in-out; /* Transição suave */
  width: 100vw; 
  
}

.history-content.open {
  max-height: 1000px; /* Altura máxima suficiente para comportar o conteúdo */
}

.history-content.closed {
  max-height: 0; /* Fechado */
}


.virtual-quee-pat{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 5vh;
  width: 100vw;
  
  color: rgb(56, 56, 56);
}

.virtual-quee-pat-header{
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  width: 100vw;
  font-size: 1.5rem;
  font-family: sans-serif;
}

.virtual-quee-pat-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 0; /* Início com altura 0 */
  transition: max-height 1.5s ease-in-out;
  width: 100vw; 
  
}

.virtual-quee-pat-content.open {
  max-height: 4000px; /* Altura máxima suficiente para comportar o conteúdo */
}

.virtual-quee-pat-content.closed {
  max-height: 0; /* Fechado */
}


.dependants{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  margin-top: 5vh;
  width: 100vw;
  color: rgb(56, 56, 56);
}

.dependants-header{
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  width: 100vw;
  font-size: 1.5rem;
  font-family: sans-serif;
}

.dependants-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 0; /* Início com altura 0 */
  transition: max-height 1s ease-in-out; /* Transição suave */
  width: 100vw; 
  
}

.dependants-content.open {
  max-height: 1500px; /* Altura máxima suficiente para comportar o conteúdo */
}

.dependants-content.closed {
  max-height: 0; /* Fechado */
}

.rules{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  width: 100vw;
  color: rgb(56, 56, 56);
  margin-bottom: 5vh;
}

.rules-header{
  background: linear-gradient(135deg, #00BFFF,  #87CEEB, #d1d8e0);
  width: 100vw;
  font-size: 1.5rem;
  font-family: sans-serif;
}

.rules-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 0; /* Início com altura 0 */
  transition: max-height 1s ease-in-out; /* Transição suave */
  width: 100vw; 
  
}

.rules-content.open {
  max-height: 1750px; /* Altura máxima suficiente para comportar o conteúdo */
}

.rules-content.closed {
  max-height: 0; /* Fechado */
}
