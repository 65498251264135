@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {

  * {
    padding:0;
    margin:0;
    vertical-align:baseline;
    list-style:none;
    border:0
    }
  
    .quee-instructions {
      width: 100%;
      min-height: 15vh;
      display: flex;
      justify-content:space-between;
      align-items: center;
      flex-direction: column;
      background-color: white;
      position: relative; /* Adiciona uma posição relativa para a div */
    }
    
    .quee-instructions::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 5%; /* Ajusta a posição inicial da borda para 10% */
      width: 90%; /* Define a largura da borda como 80% */
      height: 1.5px;
      background-color: #87CEEB;
    }

    .instructions-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      
    }

    .inService-container{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      background-color: #87CEEB;
      background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
      width: 100%;
      margin-bottom: 1vh;
      color: white;
      font-family: 'Montserrat', sans-serif;;
      font-weight: 700;
      font-size: 1.1rem;
      min-height: 1.8rem;
    }

    .in-service-container-second {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100vw;

    }

    .inService {
      font-size: 1rem;

    }

    .inServicePatient {
      color: #a2e69d; /* Cor verde mais claro */
      text-shadow: 0 0 1px rgba(162, 230, 157, 1); /* Efeito de brilho */
      
    }

    .atention {
      margin-top: 0.4rem;
      color: #d32f2ff1; /* Cor do texto branca */
      font-size: 1.2rem;
      font-weight: bold;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    .instructions{
      margin-top: 0.4rem;
      color :#818181;
      font-size: 1.1rem;
      font-weight:600;
    }

  
    }
  
  