.rules-container {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
  background-color: white;
  text-align: left;
}

.rules-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
}

.rules-subtitle {
  font-size: 20px;
  font-weight: bold;
  margin: 20px 0 10px;
}

.rules-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.rules-list li {
  margin-bottom: 10px;
}

.rules-list ul {
  list-style-type: disc;
  margin: 10px 0 0 20px;
}

@media (max-width: 600px) {
  .rules-container {
    padding: 15px;
  }

  .rules-title, .rules-subtitle {
    font-size: 18px;
    text-align: left;
  }

  .rules-list {
    padding-left: 15px;
  }

  .rules-list li {
    font-size: 14px;
  }
}
