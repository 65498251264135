@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@media only screen and (max-width: 600px) {
* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
  }

.search-patients-container{
  display: flex;
  justify-content:flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  min-height: 20vh;
  background-color: #87CEEB;
  background-image: linear-gradient(to bottom right, #87CEEB, #00BFFF);
  
}

.busca-pacientes-title{
  margin-top: 1vh;
  color: white;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1vh;
}

.input-search-container {
  
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    background-color: #f2f2f2;
    min-height: 20vh;
  
}

.search-form-input {
  width: 50vw;
  height: 4.5vh;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.search-form-input::placeholder {
text-align: center;
font-size: 0.9rem;
}

.search-register-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #f2f2f2;
  border: 2px white;
  border-style: dashed;
  width: 90vw;
  min-height:15vh ;
  font-size: 1.4rem;
  margin-top: 1vh;
  margin-bottom: 5vh;
 
}

.search-li-container {
  
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 80vw;
 
}

.patient-personal-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 1vh;
  width: 80vw;
  min-height: 16vh;
}

.personal-sub-box {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  width: 80vw;
  /* min-width: 25vw; */
 
}

.sub-box-tag{
  font-weight: bold;
  font-size: 1.3rem;
  margin-right: 1vw;
}

.sub-box-tag-text{
  font-size: 1.2rem;
  /* font-weight: 600; */
  color: rgb(101, 101, 101);
}

.patient-contact-box {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  
  width: 80vw;
 
}

.patient-contact-sub-box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;

  width: 80vw;
  /* min-width: 25vw; */
 
}

.patient-address-box{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  
  width: 80vw;
}

.patient-address-box-sec{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  
  width: 80vw;
}

/* .address-sub-box-invi{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 16vh;
  width: 18vw ;
  border: 1px solid red;
} */

.address-sub-box{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 19vh;
  max-width: 30vw;
  margin-top: 1vh;
  margin-bottom: 1vh;

  

}

.patient-plan-box{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  width: 80vw;
}

.patient-plan-box-sec{
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
 
  width: 80vw;
}

.plans-sub-box {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  min-height: 8vh;
  margin-top: 1vh;
  margin-bottom: 1vh;
 
  width: 22vw;
}

.sub-title-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
  width: 90vw;
  background-color: #cccccc6f;
  font-weight: bold;
  font-size: 1.5rem;
  color: white;

}

.sub-title-text {
  display: flex;
  justify-content: center;
  align-items:center;
}

.sem-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 79vw;
  height: 6vh;
  color: rgb(101, 101, 101);
 
}



/* CSS */
.edit-pat-infos-button {
  background-color: #222;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  max-width: none;
  min-height: 44px;
  min-width: 10px;
  outline: none;
  overflow: hidden;
  padding: 7px 17px 6px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  margin-bottom: 1.5vh;
  margin-top: 2vh;
}

.edit-pat-infos-button:hover,
.edit-pat-infos-button:focus {
  opacity: .75;
}

}