@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.links-container-media {
  background-color: white;
  width: 100vw;
  height: 25vh;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5vw;
  position: fixed; 
  z-index: 3;


}



.link-text-homecoverMedia {
  text-decoration: none;
  color: rgba(46, 46, 46, 0.742);
  font-family: 'Montserrat',sans-serif;
  font-weight: 500;
  font-size: 7vw;
 
}
