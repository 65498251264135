@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0;
  font-family: 'Montserrat', sans-serif;
 
  }

  .cover-main-ort{
    letter-spacing: 0.05rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 38vh;
    width: 100%;
    background-image: linear-gradient(to bottom, #5adaff, #5468ff);
  }

.welcome-pat-profile-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4vh;
  margin-bottom: 4vh;
}

  .welcome-pat-profile{
    color: whitesmoke;
    font-size: 2.1rem;
  }

  .auth-cover-text-box-ort {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 15vh;
    width:90vw ;
    
  }

  .revolution-ort{
    margin-top: 5vh;
    font-size: 1.8rem;
    margin-bottom: 0.5vh;
    /* font-weight: 500; */
    color: white
  }

  .ortodontia-text-cover-name{
    font-size: 2rem;
    color: #ffd700;
    font-weight: bold;
  }


  .ortodontia-text-cover{
    font-size: 1.6rem;
    color: #ffd700;
    font-weight: bold;
  }

  .patient-cover-text-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 90%;
  }

  .patient-cover-text-p{
    color: whitesmoke;
    font-size: 1.15rem;
    font-weight: 600;
  }


  .acesse-ort{
    margin-top: 0.5vh;
    font-size: 1.4rem;
    color: rgba(46, 46, 46, 0.745)
  }

  .cadastre-descubra{
    margin-top: 5vh;
    margin-bottom: 1vh;
    color: whitesmoke;
    font-size: 1.1rem;
    font-weight: 600;
  }