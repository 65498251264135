/* Container Principal */
.form-container-concluir-bene {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90vw;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
 
  margin-top: 1vh;
}

/* Título */
.form-container-concluir-bene h2 {
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Grupo de Botões */
.button-group-concluir-bene {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-group-concluir-bene button {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin: 0 5px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 40vw;
  font-weight: 600;
}

.button-group-concluir-bene button:hover {
  background-color: #0056b3;
}

/* Input */
.input-field-concluir-bene {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
}

/* Select */
.select-field-concluir-bene {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
  color: #333;
  background-color: #fff;
}

/* Botão de Submissão */
.form-container-concluir-bene button[type="submit"] {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
}

.form-container-concluir-bene button[type="submit"]:hover {
  background-color: #218838;
}

/* Mensagens de Sucesso e Erro */
.success-message-concluir-bene {
  color: #28a745;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
}

.error-message-concluir-bene {
  color: #dc3545;
  font-size: 1rem;
  text-align: center;
  margin-top: 10px;
}

/* Responsividade */
@media (max-width: 480px) {
  .form-container-concluir-bene {
    padding: 15px;
  }

  .button-group-concluir-bene button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }

  .form-container-concluir-bene button[type="submit"] {
    font-size: 1rem;
  }
}
