@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@media only screen and (max-width: 600px) {

  .main-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    max-width: 100vw;
    height: fit-content;
    padding-top: 0;
    background-color: aliceblue;


  }

  .secondary-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 95vw;
    height: fit-content;
    background-color: none;

  }

  .empty-div{
   background-color: aliceblue;
   height: 2vh;
  }
}