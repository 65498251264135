.round-button-L {
  
  width: 26px; /* Largura ajustável conforme necessário */
  height: 26px; /* Altura ajustável conforme necessário */
  border-radius: 50%; /* Torna o botão redondo */
  background-color: #2fe425; /* Vermelho */
  border: none; /* Remove a borda */
  color: #ffffff; /* Cor do texto (ícone) */
  font-size: 20px; /* Tamanho do texto (ícone) */
  cursor: pointer; /* Altera o cursor ao passar sobre o botão */
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: 600;
}

.round-button-L::after {
display: none;
}

.round-button-L:hover {
  transform: translateY(2px) scale(1.05); /* Efeito de elevação */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

.round-button {
  
  width: 26px; /* Largura ajustável conforme necessário */
  height: 26px; /* Altura ajustável conforme necessário */
  border-radius: 50%; /* Torna o botão redondo */
  background-color: #ff0000;; /* Vermelho */
  border: none; /* Remove a borda */
  color: #ffffff; /* Cor do texto (ícone) */
  font-size: 19px; /* Tamanho do texto (ícone) */
  cursor: pointer; /* Altera o cursor ao passar sobre o botão */
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: 600;

}

.round-button::after {
display: none;
}

.round-button:hover {
  transform: translateY(2px) scale(1.05); /* Efeito de elevação */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

}

