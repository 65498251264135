.social-container {
  display: flex;
  max-width: 20vw;
  height: 8.5vh;
  justify-content: space-between;
  align-items: center;

}

.face-logo {
  width: 2.5vw;
  /* height: 2vh; */
 
}

.face-logo:hover {
  width: 70px;
  height: 70px;

 
}
.insta-logo {

  height: 50px;
  width: 2.5vw;
}

.insta-logo:hover {
  width: 70px;
  height: 70px;
}


.whatsIcon-container-footer {
  height: 50px;
}
.whatsIcon-container-footer:hover {
  height: 70px;
}


.whatsIcon-footer {

height: 50px;
width: 2.5vw;
}

.whatsIcon-footer:hover {
  width: 70px;
  height: 70px;
  }