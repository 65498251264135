@media only screen and (min-width: 601px) {

* {
  padding:0;
  margin:0;
  vertical-align:baseline;
  list-style:none;
  border:0
  }

  .quee-container {
  width: 80%;
  min-height: 40vh;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  background-color: white;
  
  }

  .people-card-container {
    width: 100%;
    min-height: 10vh;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid blue; */
  }

  .people-card {
    width: 100%;
    min-height: 10vh;
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid red; */
  }

}